const dobbiesStaging =  {
  search: {
      inputBox: ".ms-search__form.bx-show input.ms-search__form-control",
      queryBtn: ".ms-search__form.bx-show .ms-search__form-submitSearch.msc-btn",
      urlParam: "q"
  },
  productClickGrid: {
      productElemWrapper: ".ms-search-result-container__Products .ms-product-search-result__item",
      // productPidAttr: "id",
      // getPidFromCB: function (pidString) {
      //     return 'p-' + pidString.match(/\d+/)[0];
      // },
      source: "PRODUCT_CLICK_GRID"
  },
  addToCartFromPDP: {
      addToCartBtnSelector: ".buttons-container .msc-add-to-cart",
      // pidSelector: "#product_details_form",
      // productPidAttr: "action",
      // getPidFromCB: function (pidString) {
      //     let pid = pidString.split('/')[1];
      //     return pid;
      // },
      // qtySelector: "#product_details_form #prodHOLDER_tbl1 #cart_quantity",
      source: "ADD_TO_CART_PDP",
  },
  // addToCartFromCartPage will be handle from the customer because they have customer dropdown.
  // addToCartFromCartPage: {
  //     cartItemWrapper: ".cart-Items-container .cart-item-detail",
  //     pidSelector: ".cart-Items-container .cart-item-detail .section2 input",
  //     productPidAttr: "value",
  //     getPidFromCB: function (pidString) {
  //         let pid = pidString.split(':')[0];
  //         return pid;
  //     },
  //     qtySelector: ".cart-Items-container .cart-item-detail .section2 a.update_quantity .selectBox-arrow",
  //     source: "ADD_TO_CART_FROM_CART_PAGE"
  // },
  // addToCartFromCartPopup: {
  //     cartItemWrapper: "#basket-quickitems .basketListItem",
  //     pidSelector: "#basket-quickitems .basketListItem .itemQuan input",
  //     productPidAttr: "class",
  //     getPidFromCB: function (pidString) {
  //         let pid = 'p-' + pidString.split(':')[0];
  //         return pid;
  //     },
  //     qtySelector: "#basket-quickitems .basketListItem .itemQuan input",
  //     qtyPlusSelector: '#basket-quickitems .basketListItem .itemQuan .increase_cart_quantity',
  //     qtyMinusSelector: '#basket-quickitems .basketListItem .itemQuan .decrease_cart_quantity',
  //     qtyDelay: 2500,
  //     source: "ADD_TO_CART_FROM_CART_POPUP",
  // },
  pageView: {
      events: {
          home: {
              uniqueSelectors: {
                  selectors: ['#renderPage[data-exp-event-id="Homepage.PageView"]']
              },
              urlIdentifiers: {
                  urls: ["https://dobbies-ecommerce.dynamics365commerce.ms/dobbies-ecomm/"],
                  exactMatch: true
              }
          },
          search: {
              uniqueSelectors: {
                  selectors: ['#renderPage[data-exp-event-id="Search.PageView"]']
              },
            searchRecall: {
                noOfResultsSelector: '.ms-search-result-container__title.result-count-search .ms-search-result__collection-title-count',
                getSRCountCB: function (str) {
                    const match = str.match(/(\d+)$/);;
                    const number = match ? match[1] : null;
                    return number;
                }
              }
          },
          productDisplay: {
              uniqueSelectors: {
                  selectors: ['#renderPage[data-exp-event-id="DefaultPdp.PageView"]']
              }
          },
          // cart: {
          //     uniqueSelectors: {
          //         selectors: ["#newshoppingcartBody"]
          //     }
          // },
          // order: {
          //     uniqueSelectors: {
          //         selectors: ["#checkoutsuccessBody"]
          //     }
          // }
      }
  }
};

export default dobbiesStaging;
